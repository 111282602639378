import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { toast } from 'react-toastify'

import { getInventoryLogsRequest } from 'store/modules/admin/logs/reducer'
import { useSortData, usePagination } from 'hooks/index'
import { TablePagination, Content, Table } from 'components/index'
import { OpenInNew, VpnKey } from 'components/Icons'
import Filter from './Filter'
import { Paper } from './styles'

async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text)
    toast.success('Chave copiada com sucesso')
  } catch (error) {
    console.error(error.message)
  }
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

const INITIAL_SORT = 'created_at'

export function InventoryLogsTable({ inventoryLogs, loading }) {
  const dispatch = useDispatch()

  const [selectedFilters, setSelectedFilters] = useState([])

  useEffect(() => {
    dispatch(getInventoryLogsRequest())
  }, [dispatch])

  const { currentSort, sortData, onSortChange } = useSortData({
    initialField: INITIAL_SORT,
    initialOrder: 'desc',
  })

  const {
    calculateNewPaginatorData,
    onChangePage,
    onChangeRowsPerPage,
    reset,
    rowsPerPage,
    page,
  } = usePagination()

  function accessAPIDocumentation() {
    const documentationUrl = process.env.REACT_APP_RSWAG_URL
    window.open(documentationUrl)
  }

  const { currentPageRecords } = calculateNewPaginatorData(
    sortData(inventoryLogs)
  )

  function renderCopyKey({ rowData: inventoryLog }) {
    return (
      <Tooltip title="Copiar chave de acesso">
        <IconButton
          size="small"
          onClick={() => copyToClipboard(inventoryLog.api_key)}
          color="primary"
        >
          <VpnKey />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Content
      title="API - Consulta estoque"
      loading={loading}
      sideBarStart
      SideComponent={
        <Filter
          data={inventoryLogs}
          loading={loading}
          resetPage={reset}
          selectedFilters={selectedFilters}
          onFiltering={setSelectedFilters}
        />
      }
      Controls={
        <Button
          color="primary"
          variant="contained"
          onClick={accessAPIDocumentation}
          endIcon={<OpenInNew />}
        >
          Documentação API
        </Button>
      }
    >
      <Paper className="border-none">
        <Table
          size="medium"
          currentSort={currentSort}
          onSortChange={onSortChange}
          data={currentPageRecords}
          columns={[
            { title: 'DATA', field: 'created_at', cellFormat: 'date' },
            {
              title: 'CNPJ',
              field: 'web_company_id',
              cellFormat: 'document',
            },
            {
              title: 'EMPRESA',
              field: 'web_company_descr',
            },
            { title: 'PRODUTO', field: 'product_id' },
            { role: 'button', renderCell: renderCopyKey },
          ]}
        />
        <TablePagination
          count={inventoryLogs.length}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
    </Content>
  )
}
