import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  logs: [],
  loading: false,
  searchParams: {},
}

const inventoryLogsSlice = createSlice({
  name: 'inventoryLogs',
  initialState,
  reducers: {
    getInventoryLogsRequest(state, { payload }) {
      state.loading = true
      Object.assign(state.searchParams, payload)
    },
    getInventoryLogsSuccess(state, { payload }) {
      state.logs = payload
      state.loading = false
    },
    getInventoryLogsFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getInventoryLogsRequest,
  getInventoryLogsSuccess,
  getInventoryLogsFailure,
} = inventoryLogsSlice.actions

export default inventoryLogsSlice.reducer
