import styled from 'styled-components'
import { Card } from 'components/index'
import { colors } from 'theme'

export const AnalysisContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 16px;
  margin: 10px;
`
export const StyledCard = styled(Card)`
  background-color: ${colors.main};
  .MuiTypography-root {
    font-size: 1.2rem;
    margin-top: 18px;
    text-align: right;
  }
`
