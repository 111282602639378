import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import { Tooltip } from '@material-ui/core'

import { cnpj } from 'consts/regexp'
import { formatFilters } from 'services/utils'
import formatter from 'services/formatter'

import { Chip, SideBar, SearchFilter } from 'components/index'
import { Trash } from 'components/Icons'
import { getInventoryLogsRequest } from 'store/modules/admin/logs/reducer'
import { FormDatePicker } from 'components/CoForm'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const removeElem = (elem, previousState) =>
  [...previousState].filter((e) => JSON.stringify(e) !== JSON.stringify(elem))

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

const StyledTrashIcon = styled(Trash)`
  margin-right: 20px;

  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`

// --------------- 𝕄𝕒𝕚𝕟 ---------------

const initialFilters = {
  created_at: '',
  web_company_id: '',
  product_id: '',
}

export default function Filter({
  data,
  onFiltering,
  loading,
  selectedFilters,
  resetPage,
  ...props
}) {
  const dispatch = useDispatch()

  const getLogs = useCallback(
    (filters) => {
      const { created_at, web_company_id, product_id } = filters

      dispatch(
        getInventoryLogsRequest({
          created_at,
          web_company_id,
          product_id,
        })
      )
    },
    [dispatch]
  )

  function handleDelete(filter) {
    const currentFilters = removeElem(filter, [...selectedFilters])

    const combinedFilters = {}
    currentFilters.forEach((filter) => {
      combinedFilters[filter.filterBy] = filter.value
    })

    getLogs(combinedFilters)
    onFiltering(currentFilters)
  }

  function handleSubmit(values) {
    const formatedDate = formatter(values.created_at).toSimpleDate()
    onFiltering(
      formatFilters({
        ...values,
        web_company_id: values.web_company_id?.replace(/\D/g, ''),
        created_at: formatedDate,
      })
    )
    getLogs({
      ...values,
      web_company_id: values.web_company_id?.replace(/\D/g, ''),
      created_at: formatedDate,
    })
    resetPage()
  }

  function handleClearFilters() {
    getLogs(initialFilters)
    onFiltering([])
  }

  return (
    <SideBar
      {...props}
      loading={loading}
      HeaderComponent={
        !isEmpty(selectedFilters) ? (
          <div className="ChipList ChipListSpacing">
            {selectedFilters.map((filter, index) => (
              <Chip
                onDelete={() => handleDelete(filter)}
                label={filter.value}
                key={index}
              />
            ))}
            <Tooltip title="Excluir filtros">
              <div>
                <StyledTrashIcon onClick={handleClearFilters} />
              </div>
            </Tooltip>
          </div>
        ) : null
      }
      ContentComponent={
        <SearchFilter
          onSubmit={handleSubmit}
          initialData={initialFilters}
          title="Pesquisar por"
          buttonLabel="Buscar"
          fields={[
            {
              name: 'created_at',
              label: 'Data',
              Component: FormDatePicker,
            },
            {
              name: 'product_id',
              label: 'Cod. produto',
            },
            {
              name: 'web_company_id',
              label: 'CNPJ',
              textMaskProps: { mask: cnpj.array },
            },
          ]}
        />
      }
    />
  )
}
