import { useSelector } from 'react-redux'

import { TabNavigation, Tabs } from 'components/TabNavigation'
import { InventoryLogsTable } from './Inventory'
import { InventoryLogsAnalysis } from './Analysis'

import { TabsContainer, StyledTabPanel } from './styles'
// --------------- 𝕄𝕒𝕚𝕟 ---------------

function AdminLogsPage() {
  const { loading, logs } = useSelector((state) => state.admin.inventoryLogs)

  return (
    <TabNavigation>
      <TabsContainer>
        <Tabs tabs={[{ label: 'Consulta estoque' }, { label: 'Análises' }]} />
      </TabsContainer>
      <StyledTabPanel index={0}>
        <InventoryLogsTable inventoryLogs={logs} loading={loading} />
      </StyledTabPanel>
      <StyledTabPanel index={1}>
        <InventoryLogsAnalysis inventoryLogs={logs} />
      </StyledTabPanel>
    </TabNavigation>
  )
}

export default AdminLogsPage
