import { all, takeLatest, call, put, select } from 'redux-saga/effects'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import {
  getInventoryLogsRequest,
  getInventoryLogsSuccess,
  getInventoryLogsFailure,
} from './reducer'

function* getInventoryLogs() {
  try {
    const searchParams = yield select(
      (state) => state.admin.inventoryLogs.searchParams
    )

    const params = {
      web_company_id: searchParams.web_company_id,
      created_at: searchParams.created_at,
      product_id: searchParams.product_id,
    }
    const response = yield call(api.get, apiEndPoints.logs.inventoryLog, {
      params,
    })
    const inventoryLogs = response.data

    yield put(getInventoryLogsSuccess(inventoryLogs))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os logs')
    yield put(getInventoryLogsFailure())
  }
}

export default all([takeLatest(getInventoryLogsRequest.type, getInventoryLogs)])
