import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, MenuItem, Select, Typography } from '@material-ui/core'
import { Person, PieChart, TrendingUp } from '@material-ui/icons'

import formatter from 'services/formatter'
import { getInventoryLogsRequest } from 'store/modules/admin/logs/reducer'
import { colors } from 'theme'
import { Graph } from 'components/Charts'
import { Content } from 'components/index'
import { RankingTable } from './rankingTable'
import { AnalysisContainer, StyledCard } from './styles'

//----------------------utils-------------------//

const aggregateByField = (logs, field) =>
  logs.reduce((acc, log) => {
    const key = log[field]
    if (key) acc[key] = (acc[key] || 0) + 1
    return acc
  }, {})

//----------------------------------------------//

export const InventoryLogsAnalysis = ({ inventoryLogs }) => {
  const [filter, setFilter] = useState('lastMonth')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getInventoryLogsRequest({
        created_at: '',
        web_company_id: '',
        product_id: '',
      })
    )
  }, [dispatch])

  const filteredLogs = useMemo(() => {
    const today = new Date()
    const dateLimits = {
      lastMonth: new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      lastWeek: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      ),
    }

    return inventoryLogs.filter((log) => {
      const logDate = new Date(log.created_at)
      return logDate >= dateLimits[filter]
    })
  }, [inventoryLogs, filter])

  const userCount = useMemo(
    () => aggregateByField(filteredLogs, 'web_company_descr'),
    [filteredLogs]
  )

  const productCount = useMemo(
    () => aggregateByField(filteredLogs, 'product_id'),
    [filteredLogs]
  )
  const topUser = useMemo(
    () =>
      Object.entries(userCount).reduce(
        (max, [user, count]) => (count > max.count ? { user, count } : max),
        { user: null, count: 0 }
      ),
    [userCount]
  )

  const topProduct = useMemo(
    () =>
      Object.entries(productCount).reduce(
        (max, [productId, count]) =>
          count > max.count ? { productId, count } : max,
        { productId: null, count: 0 }
      ),
    [productCount]
  )

  const lineData = useMemo(() => {
    const ascLogs = [...filteredLogs].reverse()
    const groupedByDate = ascLogs.reduce((acc, log) => {
      const date = formatter(log.created_at).toSimpleDate().slice(0, 5)

      acc[date] = (acc[date] || 0) + 1
      return acc
    }, {})

    return {
      labels: Object.keys(groupedByDate),
      series: [Object.values(groupedByDate)],
    }
  }, [filteredLogs])

  const totalLogs = filteredLogs.length

  return (
    <Content
      title="Análises"
      Controls={
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          displayEmpty
          variant="outlined"
          style={{ color: colors.color600, width: '200px' }}
        >
          <MenuItem value="lastMonth">Últimos 30 dias</MenuItem>
          <MenuItem value="lastWeek">Últimos 7 dias</MenuItem>
        </Select>
      }
    >
      <Box marginBottom={8}>
        <AnalysisContainer>
          <StyledCard title="Total - Consultas" Icon={TrendingUp}>
            <Typography variant="body2" color="primary">
              {totalLogs}
            </Typography>
          </StyledCard>

          <StyledCard title="Empresa com mais consultas" Icon={Person}>
            <Typography variant="body2" color="primary">
              {topUser.user || '-'}
            </Typography>
          </StyledCard>

          <StyledCard title="Produto mais buscado" Icon={PieChart}>
            <Typography variant="body2" color="primary">
              {topProduct.productId || '-'}
            </Typography>
          </StyledCard>
        </AnalysisContainer>
      </Box>
      <Box>
        <Typography variant="h6" color="primary">
          Consultas por data
        </Typography>

        <Graph
          data={lineData}
          type="Line"
          color={colors.color300}
          decimalCases={0}
        />
      </Box>

      <RankingTable
        inventoryLogs={filteredLogs}
        userCount={userCount}
        productCount={productCount}
      />
    </Content>
  )
}
