import { combineReducers } from 'redux'

import companies from './companies/reducer'
import users from './users/reducer'
import inventoryLogs from './logs/reducer'

export default combineReducers({
  companies,
  users,
  inventoryLogs,
})
