import { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'

import { Paper, Table } from 'components/index'

//----------------------utils-------------------//

const sortAndRank = (data, limit = 10) =>
  Object.entries(data)
    .sort(([, itemA], [, itemB]) => itemB - itemA)
    .slice(0, limit)
    .map(([key, count], index) => ({ key, count, rank: index + 1 }))

//----------------------------------------------//

export const RankingTable = ({ invetoryLogs, userCount, productCount }) => {
  const productsRanking = useMemo(
    () => sortAndRank(productCount),
    [productCount]
  )
  const companyRanking = useMemo(() => sortAndRank(userCount), [userCount])

  return (
    <Box
      display="grid"
      gap={2}
      mt={6}
      sx={{
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        },
      }}
    >
      <Box>
        <Typography variant="h6" color="primary" style={{ marginLeft: 12 }}>
          Ranking - 10 produtos mais buscados
        </Typography>
        <Paper style={{ margin: 12 }}>
          <Table
            size="medium"
            data={productsRanking}
            columns={[
              { title: '#', field: 'rank' },
              { title: 'PRODUTO', field: 'key' },
              { title: 'CONSULTAS', field: 'count', align: 'center' },
            ]}
          />
        </Paper>
      </Box>
      <Box>
        <Typography variant="h6" color="primary" style={{ marginLeft: 12 }}>
          Ranking - 10 empresas com mais consultas
        </Typography>
        <Paper style={{ margin: 12 }}>
          <Table
            size="medium"
            data={companyRanking}
            columns={[
              { title: '#', field: 'rank' },
              { title: 'EMPRESA', field: 'key' },
              { title: 'CONSULTAS', field: 'count', align: 'center' },
            ]}
          />
        </Paper>
      </Box>
    </Box>
  )
}
